module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SemiVoice","short_name":"SemiVoice","description":"SemiVoice 是您的半导体新闻聚合器，探索海内外各大网站半导体精选新闻，并实时更新。","start_url":"/","background_color":"#009A62","theme_color":"#009A62","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dbaefd33b08f5f2ba6c0100aff275abe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
